<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  logo: {
    type: String,
    default: null,
  },
  isTrigger: {
    type: Boolean,
    default: false,
  },
});

const actionIcon = computed(() => {
  const lowerAction = props.name.toLowerCase();
  if (
    lowerAction.includes('create') ||
    lowerAction.includes('new') ||
    lowerAction.includes('add')
  ) {
    return 'i-ph-plus-bold';
  } else if (lowerAction.includes('update') || lowerAction.includes('change')) {
    return 'i-ph-pencil-simple-bold';
  } else if (lowerAction.includes('delete') || lowerAction.includes('remove')) {
    return 'i-ph-trash-simple-bold';
  } else if (lowerAction.includes('get') || lowerAction.includes('search')) {
    return 'i-ph-magnifying-glass-bold';
  } else if (lowerAction.includes('send') || lowerAction.includes('reply')) {
    return 'i-ph-paper-plane-tilt-bold';
  } else if (props.isTrigger) {
    return 'i-ph-lightning-bold';
  } else {
    return 'i-ph-sparkle-bold';
  }
});
</script>
<template>
  <div class="bg-primary-100 p-3 flex gap-3 w-full rounded-lg justify-start">
    <UIcon
      v-if="logo"
      :name="logo"
      class="text-xl bg-white rounded-full border-gray-50 outline outline-gray-200 border"
    />
    <UIcon :name="actionIcon" class="text-xl text-primary-500" />
    <p class="body truncate">{{ name }}</p>
  </div>
</template>
