<script setup lang="ts">
import integrations, { triggers } from '@respell/integrations';
import { Background } from '@vue-flow/background';
import AppSpellCarousel from '~/components/app/AppSpellCarousel.vue';
import Sidebar from '~/components/editor/sidebar/Sidebar.vue';
import GuestTopbar from '~/components/home/GuestTopbar.vue';
import ActionItem from '~/components/integrations/ActionItem.vue';
import IntegrationCard from '~/components/integrations/IntegrationCard.vue';

definePageMeta({
  auth: {
    unauthenticatedOnly: false,
  },
});

defineOgImageScreenshot();

const { y } = useWindowScroll();
const { width } = useWindowSize();
const searchQuery = ref('');
const showMore = ref(false);

const canvasStore = useCanvasStore();
const { stepsByService } = storeToRefs(canvasStore);

const primaryKey = useRouteParams('primary');
const secondaryKey = useRouteParams('secondary');

const primary = computed(() =>
  primaryKey.value ? integrations[primaryKey.value] : null,
);
const secondary = computed(() =>
  secondaryKey.value ? integrations[secondaryKey.value] : null,
);

const primaryTriggers = computed(() =>
  Object.values(triggers[primaryKey.value] || {}),
);
const secondaryTriggers = computed(() =>
  Object.values(triggers[secondaryKey.value] || {}),
);

const seoTitle = computed(() => {
  if (secondary.value) {
    return `${primary.value?.name} + ${secondary.value?.name} Integration`;
  }
  if (primary.value) {
    return `${primary.value?.name} Integration`;
  }
  return 'Integrations';
});

const seoDescription = computed(() => {
  if (secondary.value) {
    return `<p>Connect ${primary.value?.name} to ${secondary.value?.name} with Respell. Automate workflows and keep your data in sync across apps.</p>`;
  }
  if (primary.value) {
    return primary.value?.marketingHtml ?? `<p>Integrate ${primary.value?.name} with your other apps using Respell. Automate tasks and streamline your workflow.</p>`;
  }
  return '<p>Explore all integrations offered by Respell. Connect your favorite apps and automate your workflows with ease.</p>';
});

// Use useSeoMeta to set dynamic SEO metadata
useSeoMeta({
  title: () => seoTitle.value,
  description: () => seoDescription.value,
});

const { data: integrationTemplates } = useApi('/api/integrations/templates', {
  query: { primary: primaryKey.value, secondary: secondaryKey.value },
  watch: [primaryKey, secondaryKey],
  immediate: !!primaryKey.value,
});

const primaryLabel = computed(() => `${primary.value?.name} Integrations`);
const secondaryLabel = computed(
  () => `${primary.value?.name} + ${secondary.value?.name} Integrations`,
);

const overviewText = computed(() => {
  if (secondary.value) {
    return `<p>Connect ${primary.value?.name} to ${secondary.value?.name} in just a few clicks, for free, with Respell. Our platform provides seamless, agentic integration between ${primary.value?.name}, ${secondary.value?.name}, and numerous other popular SaaS applications.\n\nExplore below for example spells using ${primary.value?.name} and ${secondary.value?.name}, as well as a list of the triggers and actions Respell supports today. Don't hesitate to reach out if we're missing anything you need.\n\nRespell is an automation platform that users love for its deep & easy-to-use integrations, AI-powered agentic spells, human-in-the-loop functionality, and powerful workflow essentials. Our spells go beyond simple automation, leveraging AI to create adaptive, intelligent workflows.</p>`;
  } else {
    return (
      primary.value?.marketingHtml ??
      `<p>Connect ${primary.value?.name} to your other apps with Respell. Coordinate across applications and keep data up-to-date where you need it, when you need it, automatically.\n\nCreate your first agentic Respell spell in minutes. Check below for the ${primary.value?.name} triggers and actions Respell supports today, and let us know if we're missing any you need.\n\nRespell is an automation platform that users love for its deep & easy-to-use integrations, AI-powered agentic workflows, human-in-the-loop functionality, and powerful spell essentials. Our spells harness the power of AI to create truly intelligent, adaptive automations.</p>`
    );
  }
});

const integrationResults = computed(() => {
  if (searchQuery.value?.length) {
    return Object.values(integrations).filter((integration) =>
      integration.name.toLowerCase().includes(searchQuery.value.toLowerCase()),
    );
  }

  return Object.values(integrations);
});

const otherIntegrations = computed(() =>
  Object.values(integrations).filter(
    (integration) =>
      ![primaryKey.value, secondaryKey.value].includes(integration.key),
  ),
);

const crumbs = computed(() => {
  const base = [
    {
      label: 'Integrations',
      to: '/apps',
    },
  ];

  if (primary.value) {
    base.push({
      label: primaryLabel.value,
      to: `/apps/${primaryKey.value}`,
    });
  }

  if (secondary.value) {
    base.push({
      label: secondaryLabel.value,
      to: `/apps/${primaryKey.value}/${secondaryKey.value}`,
    });
  }

  return base;
});
</script>
<template>
  <div class="grow flex flex-col bg-gray-50">
    <GuestTopbar :y="y" />

    <UContainer>
      <div v-if="primary" class="flex flex-col gap-8 items-start">
        <nav>
          <UBreadcrumb :links="crumbs" />
        </nav>
        <div class="flex gap-14 items-start relative">
          <aside
            class="sticky top-24 flex flex-col gap-6 justify-start shrink-0"
          >
            <div class="flex" :class="{ '-space-x-8 space-y-24': !!secondary }">
              <UIcon
                v-for="service in [primary, ...(secondary ? [secondary] : [])]"
                :key="service?.name"
                :name="service.icon"
                :alt="`${service.name} integration logo`"
                class="rounded-md text-9xl border-gray-50 outline outline-4 border-8 outline-gray-200 bg-white"
              />
            </div>
            <UButton
              block
              :label="`Connect ${primary.name}`"
              size="xl"
              to="/account/linked-accounts"
            />
          </aside>
          <div class="flex flex-col items-start gap-10">
            <h1 class="main-title">
              {{ secondary ? secondaryLabel : primaryLabel }}
            </h1>

            <div v-if="Object.values(stepsByService).length" class="contents">
              <div class="relative">
                <Sidebar
                  class="pointer-events-none select-none origin-top-left absolute -top-5 left-0"
                  :style="{
                    scale: `${Math.min(0.9, Math.max(0.5, width / 1500))}`,
                  }"
                  @click.stop.prevent
                  @mousedown.stop.prevent
                  @mouseup.stop.prevent
                />
              </div>

              <Background
                pattern-color="#787893"
                :gap="20"
                :size="1.5"
                class="min-h-[25rem] max-h-[37rem] !block"
                :style="{ aspectRatio: '16/11' }"
              />
            </div>

            <section class="flex gap-4 flex-col items-start">
              <h2 class="main-title">Overview</h2>
              <div
                class="body"
                style="white-space: pre-wrap"
                v-bind:innerHTML="overviewText"
              />
            </section>

            <section class="gap-6 grid grid-cols-2 w-full items-start">
              <div class="flex flex-col w-full gap-4 items-start">
                <h3 class="main-title">
                  {{ secondary ? primary?.name + ' ' : '' }}Triggers
                </h3>
                <p v-if="!primaryTriggers.length" class="body dimmed">
                  No {{ primary.name }} triggers yet
                </p>
                <ActionItem
                  v-for="trigger in primaryTriggers"
                  v-else
                  :key="trigger.key"
                  :name="trigger.name"
                  :logo="secondary ? primary.icon : null"
                  is-trigger
                />

                <div v-if="secondary" class="contents">
                  <h3 class="main-title mt-8">{{ secondary.name }} Triggers</h3>
                  <p v-if="!secondaryTriggers.length" class="body dimmed">
                    No {{ secondary.name }} triggers yet
                  </p>
                  <ActionItem
                    v-for="trigger in secondaryTriggers"
                    v-else
                    :key="trigger.key"
                    :name="trigger.name"
                    :logo="secondary.icon"
                    is-trigger
                  />
                </div>
              </div>

              <div class="flex flex-col gap-4 items-start">
                <h3 class="main-title">
                  {{ secondary ? primary?.name + ' ' : '' }}Steps
                </h3>
                <p
                  v-if="!Object.values(stepsByService[primaryKey] ?? {}).length"
                  class="body dimmed"
                >
                  No {{ primary.name }} steps yet
                </p>
                <ActionItem
                  v-for="(step, key) in stepsByService[primaryKey]"
                  v-else
                  :key="key"
                  :name="step.name"
                  :logo="secondary ? primary.icon : null"
                />

                <div
                  v-if="secondary"
                  class="flex flex-col gap-4 w-full items-start"
                >
                  <h3 class="main-title mt-8">{{ secondary.name }} Steps</h3>
                  <p
                    v-if="
                      !Object.values(stepsByService[secondaryKey] ?? {}).length
                    "
                    class="body dimmed"
                  >
                    No {{ secondary.name }} steps yet
                  </p>
                  <ActionItem
                    v-for="(step, key) in stepsByService[secondaryKey]"
                    v-else
                    :key="key"
                    :name="step.name"
                    :logo="secondary.icon"
                  />
                </div>
              </div>
            </section>

            <section class="flex flex-col gap-4 items-start">
              <h3 class="main-title">Templates</h3>

              <AppSpellCarousel
                :spells="integrationTemplates"
                class="w-full"
                templated
              />
            </section>

            <section class="flex flex-col gap-8 items-start w-full">
              <span class="flex justify-between w-full">
                <h3 class="main-title">
                  Connect {{ primary.name }} to your other apps
                </h3>

                <UButton
                  :label="showMore ? 'Show less' : 'Show more'"
                  variant="link"
                  color="gray"
                  @click="showMore = !showMore"
                />
              </span>

              <div class="flex flex-wrap gap-6">
                <IntegrationCard
                  v-for="integration in otherIntegrations.slice(
                    0,
                    showMore ? Infinity : 10,
                  )"
                  :key="integration.key"
                  :service="integration"
                  :to="`/apps/${primaryKey}/${integration.key}`"
                />
              </div>
            </section>
          </div>
        </div>
      </div>

      <div v-else class="flex flex-col gap-6 items-center max-w-4xl">
        <h1 class="main-title">Integrations</h1>
        <p class="body dimmed">
          Explore all the integrations that Respell has to offer.
        </p>
        <UInput
          v-model="searchQuery"
          placeholder="Search for integrations..."
          class="max-w-3xl w-full"
          icon="i-ph-magnifying-glass-bold"
          color="white"
          size="xl"
        />
        <section class="flex flex-wrap gap-6 w-full">
          <IntegrationCard
            v-for="integration in integrationResults"
            :key="integration.key"
            :service="integration"
            :to="`/apps/${integration.key}`"
          />
        </section>
      </div>
    </UContainer>
  </div>
</template>
<style scoped lang="scss">
.vue-flow__container {
  position: static;
}
</style>
